import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  traditionalNavConfigNested,
  ekycServiceNavConfigNested,
} from "components/layouts/config-nav";
import type { StandardObject } from "utils";
import type { NavConfigGroup } from "components/layouts/config-nav";

interface NavState {
  traditional: StandardObject<boolean>;
  ekycService: StandardObject<boolean>;
}

interface setOpenStatesPayload {
  key: "traditional" | "ekycService";
  openState: StandardObject<boolean>;
}

const getInitialOpenState = (navConfigNested: NavConfigGroup[]) => {
  return navConfigNested.reduce((ret: { [key: string]: boolean }, group) => {
    group.children.forEach((lv1Itm) => {
      if (lv1Itm.children && lv1Itm.children.length) {
        ret[lv1Itm.key] = false;
      }
    });
    return ret;
  }, {});
};

const initialState: NavState = {
  traditional: getInitialOpenState(traditionalNavConfigNested),
  ekycService: getInitialOpenState(ekycServiceNavConfigNested),
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setOpenStates: (state, action: PayloadAction<setOpenStatesPayload>) => {
      const key = action.payload.key;
      switch (key) {
        case "traditional":
          state.traditional = {
            ...state.traditional,
            ...action.payload.openState,
          };
          break;
        case "ekycService":
          state.ekycService = {
            ...state.ekycService,
            ...action.payload.openState,
          };
          break;
      }
    },
  },
});

const selectNav = (state: { nav: NavState }) => state.nav;

export const selectNavState = createSelector([selectNav], (s: NavState) => {
  return {
    traditional: s.traditional,
    ekycService: s.ekycService,
  };
});

export const { setOpenStates } = navSlice.actions;

export default navSlice.reducer;

import { StandardObject } from "utils";

export interface Tree {
  _root: TreeNode | null;
  _nodeMap: StandardObject<TreeNode>;
  contains: (nodeId: string | number) => boolean;
  getNodeById: (nodeId: string | number) => TreeNode | undefined;
  addNew: (id: string | number, nContent: StandardObject) => TreeNode;
  setRoot: (id: string | number, nContent: StandardObject) => TreeNode;
  getRoot: () => TreeNode | null;
  isRoot: (node: TreeNode) => boolean;
  isLeaf: (node: TreeNode) => boolean;
  addChildTo: (
    nParent: TreeNode,
    id: string | number,
    nContent: StandardObject
  ) => TreeNode;
  getDepth: (node: TreeNode) => number;
  getAncestors: (node: TreeNode) => TreeNode[];
  deleteLeaf: (node: TreeNode) => void;
  preOrder: (node?: TreeNode) => TreeNode[];
  postOrder: (node?: TreeNode) => TreeNode[];
}

const buildTree = (): Tree => {
  let instance: any = {};
  instance._root = null;
  instance._nodeMap = {};
  instance.contains = (nodeId: string | number): boolean => {
    return String(nodeId) in instance._nodeMap;
  };
  instance.getNodeById = (nodeId: string | number): TreeNode | undefined => {
    return instance._nodeMap[String(nodeId)];
  };
  instance.addNew = (
    id: string | number,
    nContent: StandardObject
  ): TreeNode => {
    const node = buildNode(String(id));
    Object.keys(nContent).forEach((k) => {
      const property = nContent[k];
      node.setProperty(k, property);
    });
    instance._nodeMap[String(id)] = node;
    return node;
  };
  instance.setRoot = (
    id: string | number,
    nContent: StandardObject
  ): TreeNode => {
    if (instance._root === null) {
      const root = instance.addNew(id, nContent);
      root.setParent(null);
      instance._root = root;
    } else {
      console.error("Root already exists, failed to setRoot");
    }
    return instance._root;
  };
  instance.getRoot = (): TreeNode | null => {
    return instance._root;
  };
  instance.isRoot = (node: TreeNode): boolean => {
    return node.id === instance._root.id;
  };
  instance.isLeaf = (node: TreeNode): boolean => {
    return node.getChildren().length === 0;
  };
  instance.addChildTo = (
    nParent: TreeNode,
    id: string | number,
    nContent: StandardObject
  ): TreeNode => {
    // Find Node from nodeMap
    let childNode: TreeNode;
    if (String(id) in instance._nodeMap) {
      childNode = instance._nodeMap[String(id)];
      Object.keys(nContent).forEach((k) => {
        const property = nContent[k];
        childNode.setProperty(k, property);
      });
    } else {
      childNode = instance.addNew(id, nContent);
    }
    nParent.addChild(childNode);
    return childNode;
  };
  instance.getDepth = (node: TreeNode): number => {
    let depth;
    if (instance.isRoot(node)) {
      depth = 0;
    } else {
      depth = 1 + instance.getDepth(node.getParent());
    }
    return depth;
  };
  instance.getAncestors = (node: TreeNode): TreeNode[] => {
    const ret = [];
    let n = node;
    while (!instance.isRoot(n)) {
      let p = n.getParent();
      if (p === null) {
        break;
      }
      ret.push(p);
      n = p;
    }
    return ret;
  };
  instance.deleteLeaf = (node: TreeNode): void => {
    if (!instance.isLeaf(node)) {
      return;
    }
    // Remove from parent
    const nParent = node.getParent();
    if (nParent !== null) {
      nParent.deleteChild(node.id);
    }
    // Remove from nodeMap
    delete instance._nodeMap[node.id];
  };
  instance.preOrder = (node?: TreeNode): TreeNode[] => {
    const startNode = node ? node : instance._root;
    const ret: TreeNode[] = [];
    instance._preOrder(startNode, ret);
    return ret;
  };
  instance._preOrder = (node: TreeNode, ret: TreeNode[]): void => {
    ret.push(node);
    const childs = node.getChildren();
    childs.forEach((child) => {
      instance._preOrder(child, ret);
    });
  };
  instance.postOrder = (node?: TreeNode): TreeNode[] => {
    const startNode = node ? node : instance._root;
    const ret: TreeNode[] = [];
    instance._postOrder(startNode, ret);
    return ret;
  };
  instance._postOrder = (node: TreeNode, ret: TreeNode[]): void => {
    const childs = node.getChildren();
    childs.forEach((child) => {
      instance._postOrder(child, ret);
    });
    ret.push(node);
  };
  return instance as Tree;
};

export interface TreeNode {
  id: string | number;
  _nParent: TreeNode | null;
  _nChildren: TreeNode[];
  _properties: StandardObject;
  setParent: (parentNode: TreeNode) => TreeNode;
  getParent: () => TreeNode | null;
  addChild: (childNode: TreeNode) => TreeNode;
  deleteChild: (childId: string | number) => void;
  getChildren: () => TreeNode[];
  setProperty: (propertyName: string, property: any) => TreeNode;
  getProperty: (propertyName: string) => any;
}

const buildNode = (nodeId: string | number): TreeNode => {
  let instance: any = {};
  instance.id = String(nodeId);
  instance._nParent = null;
  instance._nChildren = [];
  instance._properties = {};

  instance.setParent = (parentNode: TreeNode): TreeNode => {
    instance._nParent = parentNode;
    return instance;
  };
  instance.getParent = (): TreeNode | null => {
    return instance._nParent;
  };
  instance.addChild = (childNode: TreeNode): TreeNode => {
    instance._nChildren.push(childNode);
    childNode.setParent(instance);
    return instance;
  };
  instance.deleteChild = (childId: string | number): void => {
    instance._nChildren = instance._nChildren.filter(
      (c: TreeNode) => String(c.id) !== String(childId)
    );
    return;
  };
  instance.getChildren = (): TreeNode[] => {
    return instance._nChildren;
  };
  instance.setProperty = (propertyName: string, property: any): TreeNode => {
    instance._properties[propertyName] = property;
    return instance;
  };
  instance.getProperty = (propertyName: string): any => {
    // Return deep copy
    return propertyName in instance._properties
      ? JSON.parse(JSON.stringify(instance._properties[propertyName]))
      : null;
  };
  return instance as TreeNode;
};

export default buildTree;

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e1193e8e57450a6c8ba1c1d28f07af96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","zh"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"verbose":true,"trailingSlash":"never","redirect":false,"i18nextOptions":{"supportedLngs":["en","zh"],"defaultNS":"common","returnObjects":false,"returnNull":false,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

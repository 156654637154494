import { alpha } from "@mui/material/styles";
import { PaletteMode, PaletteOptions } from "@mui/material";
import { dark, light, Palette } from "@mui/material/styles/createPalette";
import { TypeBackground } from "@mui/material";
import { TypeText } from "@mui/material";

interface CustomTypeText extends TypeText {
  placeholder?: string;
}

interface CustomTypeBackground extends TypeBackground {
  neutral?: string;
  primary?: string;
}

export interface CustomPaletteOptions extends PaletteOptions {
  text: CustomTypeText;
  background: CustomTypeBackground
}

export interface CustomPalette extends Palette {
  text: CustomTypeText;
  background: CustomTypeBackground;
}

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  lighter: "#DFE3E8",
  400: "#C4CDD5",
  light: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  main: "#637381",
  700: "#454F5B",
  800: "#212B36",
  dark: "#212B36",
  900: "#161C24",
  darker: "#161C24",
};

export const primary = {
  lighter: "#c9f3ff",
  light: "#94e8ff",
  main: "#2191d2",
  dark: "#4584c5",
  darker: "#616dae",
  contrastText: "#FFFFFF",
};

export const secondary = {
  lighter: "#EFD6FF",
  light: "#C684FF",
  main: "#8661B4",
  dark: "#8E33FF",
  darker: "#5119B7",
  contrastText: "#FFFFFF",
};

export const info = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#006C9C",
  darker: "#003768",
  contrastText: "#FFFFFF",
};

export const success = {
  lighter: "#C8FAD6",
  light: "#5BE49B",
  main: "#00A76F",
  dark: "#007867",
  darker: "#004B50",
  contrastText: "#FFFFFF",
};

export const warning = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: grey[800],
};

export const error = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF5630",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#FFFFFF",
};

export const disabled = {
  lighter: "#F9FAFB",
  light: "#F4F6F8",
  main: "#C4CDD5",
  dark: "#637381",
  darker: "#454F5B",
  contrastText: "#FFFFFF",
};

export const common = {
  black: "#000000",
  white: "#FFFFFF",
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
  active: grey[600],
  selectedOpacity: 0.16,
  focusOpacity: 0.12,
  activatedOpacity: 0.24,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  disabled,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------


export function palette(paletteType: PaletteMode = "light"): CustomPaletteOptions {
  return {
    ...base,
    mode: paletteType,
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
      placeholder: grey[400],
    },
    background: {
      paper: "#FFFFFF",
      default: grey[100],
      neutral: grey[200],
      primary: primary.main,
    },
    action: action,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  };
}

